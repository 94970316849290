// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { AppProvider, AppContext } from './AppContext';
import Base from './Base';
import Research from './Research';
import Assistant from './Assistant';
import System from './System';
import Configurations from './Configurations';
import Ontology from './Ontology';
import PromptGuidance from './PromptGuidance';
import Login from './Login';
import ErrorBoundary from './ErrorBoundary';
import { auth } from './firebase';

function AppContent() {
  const [darkState, setDarkState] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [mongoConnected, setMongoConnected] = useState(false);

  let theme = createTheme({
    palette: {
      mode: darkState ? 'dark' : 'light',
    },
  });
  theme = responsiveFontSizes(theme);

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setCheckingAuth(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkMongoConnection = async () => {
      try {
        const response = await fetch('/api/system/check-mongo-connection');
        const data = await response.json();
        setMongoConnected(data.mongo_connected);
      } catch (error) {
        console.log('Error checking MongoDB connection:', error);
        setMongoConnected(false);
      }
    };

    checkMongoConnection();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <AppContext.Consumer>
      {({ resetAppState }) => (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router basename="/">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                element={
                  <ProtectedRoute>
                    <Base title="LGT Research AI" onThemeToggle={handleThemeChange} darkState={darkState}>
                      <Outlet />
                    </Base>
                  </ProtectedRoute>
                }
              >
                <Route path="/" element={<Assistant />} />
                <Route path="/research" element={<Research />} />
                <Route path="/assistant" element={<Assistant />} />
                <Route path="/ontology" element={<Ontology />} />
                <Route path="/system" element={<System />} />
                <Route path="/configurations" element={<Configurations />} />
                <Route path="/prompt-guidance" element={<PromptGuidance />} />
              </Route>
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </ThemeProvider>
      )}
    </AppContext.Consumer>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;