// AppContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from './api';
import { auth } from './firebase';

export const AppContext = createContext();

const getInitialState = () => ({
  selectedPdfs: [],
  userInput: '',
  systemPrompt: '',
  chatMessages: [],
  selectedModel: 'gpt-4o',
  workflowProgress: '',
  workflowSteps: [],
  progressMessages: [],
  estimatedTokens: 0,
  tokenLimit: 0,
  pdfTokenCounts: {},
});

export const AppProvider = ({ children }) => {
  const [refreshResearch, setRefreshResearch] = useState(false);
  const [assistantState, setAssistantState] = useState(() => {
    const savedState = localStorage.getItem('assistantState');
    const lastSession = localStorage.getItem('lastSession');
    const currentSession = Date.now();
    
    // If it's a new session, clear the state
    if (!lastSession || (currentSession - parseInt(lastSession) > 30 * 60 * 1000)) { // 30 minutes threshold
      localStorage.removeItem('assistantState');
      return getInitialState();
    }
    
    return savedState ? JSON.parse(savedState) : getInitialState();
  });

  useEffect(() => {
    const fetchSystemPrompt = async () => {
      try {
        const response = await api.get('/api/system/system-prompt');
        setAssistantState(prevState => ({
          ...prevState,
          systemPrompt: response.data.systemPrompt
        }));
      } catch (error) {
        console.error('Failed to fetch system prompt:', error);
      }
    };

    fetchSystemPrompt();
  }, []);

  useEffect(() => {
    localStorage.setItem('assistantState', JSON.stringify(assistantState));
    localStorage.setItem('lastSession', Date.now().toString());
  }, [assistantState]);

  const resetAppState = () => {
    setAssistantState(getInitialState());
    localStorage.removeItem('assistantState');
    localStorage.removeItem('lastSession');
  };

  return (
    <AppContext.Provider value={{ 
      refreshResearch, 
      setRefreshResearch, 
      assistantState, 
      setAssistantState,
      resetAppState 
    }}>
      {children}
    </AppContext.Provider>
  );
};