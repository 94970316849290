// frontend/src/AIResponse.js

import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, Chip, Tooltip, Fade, IconButton, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import AutorenewIcon from '@mui/icons-material/AutoAwesome';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CategoryIcon from '@mui/icons-material/Category';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ScrollableBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  maxHeight: '100%',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
}));

const ResponseContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'linear-gradient(145deg, #2c3e50, #34495e)',
  color: '#ecf0f1',
  borderRadius: '10px',
  boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  '&:hover': {
    boxShadow: '0 8px 20px rgba(0,0,0,0.15)',
  },
}));

const StyledReactMarkdown = styled(ReactMarkdown)({
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    marginTop: '0.5em',
    marginBottom: '0.25em',
    fontWeight: 'bold',
    color: '#3498db',
    fontSize: '1rem',
  },
  '& p': {
    marginBottom: '0.25em',
    fontSize: '0.875rem',
  },
  '& ul, & ol': {
    paddingLeft: '1.25em',
    marginBottom: '0.25em',
  },
  '& li': {
    marginBottom: '0.125em',
    fontSize: '0.875rem',
  },
  '& strong': {
    color: '#2ecc71',
  },
  '& em': {
    color: '#e74c3c',
  },
});

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.25),
  backgroundColor: 'rgba(52, 152, 219, 0.2)',
  color: '#ecf0f1',
  border: '1px solid rgba(52, 152, 219, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(52, 152, 219, 0.3)',
  },
  height: '20px',
  '& .MuiChip-label': {
    fontSize: '0.65rem',
    padding: '0 6px',
  },
}));

const ChunkingChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.25),
  backgroundColor: 'rgba(155, 89, 182, 0.2)',
  color: '#ecf0f1',
  border: '1px solid rgba(155, 89, 182, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(155, 89, 182, 0.3)',
  },
  height: '20px',
  '& .MuiChip-label': {
    fontSize: '0.65rem',
    padding: '0 6px',
  },
}));

const OntologyContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'linear-gradient(145deg, #2c3e50, #34495e)',
  color: '#ecf0f1',
  borderRadius: '10px',
  boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  '&:hover': {
    boxShadow: '0 8px 20px rgba(0,0,0,0.15)',
  },
}));

const ConceptChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: 'rgba(46, 204, 113, 0.2)',
  color: '#ecf0f1',
  border: '1px solid rgba(46, 204, 113, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(46, 204, 113, 0.3)',
  },
  height: '24px',
  '& .MuiChip-label': {
    fontSize: '0.75rem',
    padding: '0 8px',
  },
}));

const OntologyUpdateInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: 'rgba(46, 204, 113, 0.1)',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
}));

const AIResponse = ({ chatMessages, isLoading, showOntologyInsights }) => {
  const scrollableBoxRef = useRef(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (scrollableBoxRef.current) {
      scrollableBoxRef.current.scrollTop = scrollableBoxRef.current.scrollHeight;
    }
  }, [chatMessages, isLoading]);

  const formatNumber = (num) => {
    return num !== undefined && num !== null ? num.toLocaleString() : 'N/A';
  };

  const renderMessage = (message) => {
    if (message.type === 'progress') {
      return (
        <Box>
          {message.messages.map((msg, index) => (
            <Typography key={index} variant="body2" color="text.secondary" fontSize="0.75rem">
              {msg}
            </Typography>
          ))}
        </Box>
      );
    }
    if (typeof message === 'string') {
      return message;
    } else if (message.message) {
      return message.message;
    } else if (message.text) {
      return message.text;
    } else {
      return JSON.stringify(message);
    }
  };

  const processMessageForCopy = (message) => {
    let processedMessage = message.replace(/<[^>]*>/g, '');
    processedMessage = processedMessage.replace(/#{1,6}\s?/g, '');
    processedMessage = processedMessage.replace(/\*\*(.*?)\*\*/g, '$1');
    processedMessage = processedMessage.replace(/\*(.*?)\*/g, '$1');
    processedMessage = processedMessage.replace(/^\s*[-*+]\s/gm, '• ');
    processedMessage = processedMessage.replace(/\n{3,}/g, '\n\n');
    return processedMessage.trim();
  };

  const handleCopy = (message, index) => {
    const processedText = processMessageForCopy(renderMessage(message));
    navigator.clipboard.writeText(processedText).then(() => {
      setCopiedIndex(index);
      setSnackbarOpen(true);
      setTimeout(() => {
        setCopiedIndex(null);
        setSnackbarOpen(false);
      }, 2000);
    });
  };

  const renderOntologyMetrics = (message) => {
    if (!showOntologyInsights || !message || (!message.extractedConcepts && !message.pendingUpdates)) return null;
  
    const extractedConceptsCount = message.extractedConcepts ? message.extractedConcepts.length : 0;
    const pendingUpdatesCount = message.pendingUpdates ? message.pendingUpdates.length : 0;
  
    return (
      <OntologyContainer elevation={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="subtitle2" color="primary.light" fontSize="0.75rem">
            Ontology Insights
          </Typography>
          <Box display="flex" alignItems="center">
            <Tooltip title="Extracted Concepts">
              <InfoChip
                label={`${extractedConceptsCount} extracted`}
                size="small"
                icon={<CategoryIcon fontSize="small" />}
              />
            </Tooltip>
            <Tooltip title="Pending Updates">
              <InfoChip
                label={`${pendingUpdatesCount} pending`}
                size="small"
                icon={<AddCircleOutlineIcon fontSize="small" />}
              />
            </Tooltip>
          </Box>
        </Box>
        {message.extractedConcepts && message.extractedConcepts.length > 0 && (
          <>
            <Typography variant="subtitle2" color="primary.light" fontSize="0.75rem" gutterBottom>
              Extracted Concepts (Input Enrichment)
            </Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="center">
              {message.extractedConcepts.map((concept, index) => (
                <ConceptChip
                  key={index}
                  label={concept}
                  size="small"
                />
              ))}
            </Box>
          </>
        )}
        {message.pendingUpdates && message.pendingUpdates.length > 0 && (
          <>
            <Typography variant="subtitle2" color="primary.light" fontSize="0.75rem" gutterBottom mt={2}>
              Pending Updates (Investment-Related Concepts Not Already in the Ontology)
            </Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="center">
              {message.pendingUpdates.map((concept, index) => (
                <ConceptChip
                  key={index}
                  label={concept}
                  size="small"
                  color="secondary"
                />
              ))}
            </Box>
          </>
        )}
        <OntologyUpdateInfo>
          <InfoIcon fontSize="small" sx={{ marginRight: 1, color: 'primary.light' }} />
          <Typography variant="caption" color="primary.light">
            Extracted concepts enrich the input. Pending updates are new concepts for potential addition to the ontology.
          </Typography>
        </OntologyUpdateInfo>
      </OntologyContainer>
    );
  };

  return (
    <ScrollableBox ref={scrollableBoxRef}>
      {chatMessages.map((message, index) => (
        <Fade in={true} key={index} timeout={500}>
          <Box>
            {message.sender === 'ai' && renderOntologyMetrics(message)}
            <ResponseContainer elevation={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2" color="primary.light" fontSize="0.75rem">
                  {message.sender === 'ai' ? `AI (${message.model})` : message.sender === 'system' ? 'System' : 'You'}
                </Typography>
                {message.sender === 'ai' && (
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-end" alignItems="center">
                    <Tooltip title="Copy Response">
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(message, index)}
                        sx={{ color: copiedIndex === index ? 'success.main' : 'inherit', padding: '2px' }}
                      >
                        {copiedIndex === index ? <CheckCircleIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="AI Response Time">
                      <InfoChip
                        label={`${message.completion_time?.toFixed(2)}s`}
                        size="small"
                        icon={<span role="img" aria-label="clock" style={{fontSize: '0.65rem'}}>⏱️</span>}
                      />
                    </Tooltip>
                    <Tooltip title="Total Tokens">
                      <InfoChip
                        label={`${formatNumber(message.estimated_total_tokens)} tokens`}
                        size="small"
                        icon={<span role="img" aria-label="total" style={{fontSize: '0.65rem'}}>Σ</span>}
                      />
                    </Tooltip>
                    {message.chunked && (
                      <Tooltip title="Input was processed in chunks">
                        <ChunkingChip
                          label="Chunked"
                          size="small"
                          icon={<AutorenewIcon fontSize="small" />}
                        />
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Box>
              <StyledReactMarkdown>
                {renderMessage(message)}
              </StyledReactMarkdown>
            </ResponseContainer>
          </Box>
        </Fade>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        message="Content copied to clipboard"
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      />
    </ScrollableBox>
  );
};

export default React.memo(AIResponse);