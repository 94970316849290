// In SelectedResearch.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const ScrollableList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  color: theme.palette.text.secondary,
}));

const MetadataText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));

const SelectedResearch = React.memo(({ selectedPdfs }) => {
  return (
    <Container>
      {selectedPdfs.length === 0 ? (
        <EmptyState>
          <Typography variant="body2">No PDFs selected</Typography>
        </EmptyState>
      ) : (
        <ScrollableList>
          {selectedPdfs.map((pdf) => (
            <ListItem key={pdf.filename}>
              <ListItemText 
                primary={pdf.filename} 
                secondary={
                  <>
                    <MetadataText>Report Title: {pdf.title || 'N/A'}</MetadataText>
                    <MetadataText>Report Date: {pdf.reportDate || 'N/A'}</MetadataText>
                    <MetadataText>Author(s): {pdf.authors || 'N/A'}</MetadataText>
                    <MetadataText>Token Estimate: {pdf.token_count ? pdf.token_count.toLocaleString() : 'N/A'}</MetadataText>
                  </>
                }
                primaryTypographyProps={{ 
                  variant: 'body2',
                  style: { 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }
                }}
              />
            </ListItem>
          ))}
        </ScrollableList>
      )}
    </Container>
  );
});

export default React.memo(SelectedResearch);