// frontend/src/Assistant.js

import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import api from './api';
import { Box, Grid, Typography, TextField, Button, Tooltip, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AIModelToggle from './AIModelToggle';
import SelectedResearch from './SelectedResearch';
import AIResponse from './AIResponse';
import ProgressUpdates from './ProgressUpdates';
import { AppContext } from './AppContext';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';

const GlobalStyle = styled('div')({
  '& *::-webkit-scrollbar': {
    width: '6px',
  },
  '& *::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '3px',
  },
  '& *::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
});

const DashboardCard = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 0),
}));

const ColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const TopHalf = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
}));

const BottomHalf = styled(Box)(({ theme }) => ({
  height: '60%',
  display: 'flex',
  flexDirection: 'column',
}));

const SystemInstructionsBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiInputBase-input': {
    height: '100% !important',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '3px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
}));

const SystemInstructionsHeader = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const UserInputBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '70%',
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiInputBase-input': {
    height: '100% !important',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '3px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  '& .MuiOutlinedInput-root': {
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    overflowY: 'auto',
    height: '100% !important', 
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '3px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  minWidth: 'auto',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '150px',
  height: '36px',
  fontSize: '0.875rem',
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
}));

const Assistant = () => {
  const location = useLocation();
  const { assistantState, setAssistantState, resetAppState } = useContext(AppContext);
  const {
    selectedPdfs,
    userInput,
    systemPrompt,
    chatMessages,
    selectedModel,
    workflowProgress,
    workflowSteps,
    progressMessages,
  } = assistantState;

  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [systemInstructionsOpen, setSystemInstructionsOpen] = useState(false);
  const [aiProcessId, setAiProcessId] = useState(null);
  const abortControllerRef = useRef(null);
  const [showOntologyInsights, setShowOntologyInsights] = useState(true);

  const updateAssistantState = useCallback((newState) => {
    setAssistantState(prevState => {
      const updatedState = { ...prevState, ...newState };
      if (!Array.isArray(updatedState.selectedPdfs)) {
        updatedState.selectedPdfs = [];
      }
      return updatedState;
    });
  }, [setAssistantState]);

  useEffect(() => {
    if (location.state?.pdfsToAdd) {
      const newPdfs = location.state.pdfsToAdd.filter(
        newPdf => !selectedPdfs.some(existingPdf => existingPdf.filename === newPdf.filename)
      );
      updateAssistantState({ 
        selectedPdfs: [...selectedPdfs, ...newPdfs]
      });
      setIsInitialLoad(true);
    }
  }, [location.state, selectedPdfs, updateAssistantState]);

  const handleResetAssistant = useCallback(() => {
    resetAppState();
    updateAssistantState({
      selectedPdfs: [],
      userInput: '',
      systemPrompt: '',
      chatMessages: [],
      workflowProgress: '',
      workflowSteps: [],
      progressMessages: [],
    });
  }, [resetAppState, updateAssistantState]);

  const handleResetConversation = useCallback(() => {
    updateAssistantState({
      chatMessages: [],
      workflowProgress: '',
      workflowSteps: [],
      progressMessages: [],
    });
  }, [updateAssistantState]);

  const ToggleButton = styled(Button)(({ theme }) => ({
    minWidth: '150px',
    height: '36px',
    fontSize: '0.875rem',
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2),
  }));

  const handleSendMessage = useCallback(async () => {
    if (isRunning) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setIsRunning(false);
      setIsLoading(false);
      updateAssistantState({
        workflowProgress: '',
        workflowSteps: [],
        progressMessages: [],
      });
      setAiProcessId(null);
      return;
    }
  
    setIsLoading(true);
    setIsRunning(true);
    updateAssistantState({
      workflowSteps: [],
      progressMessages: ['Initializing AI response generation'],
      chatMessages: [
        ...chatMessages,
        { sender: 'user', message: userInput }
      ],
    });
  
    try {
      const response = await api.post('/api/ai/start_airesponse', {
        model: selectedModel,
        pdf_filenames: selectedPdfs.map(pdf => pdf.filename),
        user_input: userInput,
        system_prompt: systemPrompt,
        chunk_option: true,
      });

      setAiProcessId(response.data.process_id);
    } catch (error) {
      console.error("Failed to start AI Input Workflow", error);
      let errorMessage = "Failed to start AI process. Please try again.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      updateAssistantState({
        chatMessages: [
          ...chatMessages,
          { text: errorMessage, sender: 'system', type: 'error' },
        ],
        workflowProgress: 'Error: ' + errorMessage,
        progressMessages: [...progressMessages, 'Error: ' + errorMessage],
      });
      setIsLoading(false);
      setIsRunning(false);
      setAiProcessId(null);
    }
  }, [selectedModel, chatMessages, progressMessages, selectedPdfs, systemPrompt, userInput, updateAssistantState, isRunning]);

  const handleAiComplete = useCallback((success) => {
    setIsLoading(false);
    setIsRunning(false);
    if (success) {
      api.get(`/api/ai/result/${aiProcessId}`)
        .then(response => {
          const formattedResponse = response.data;
          console.log("AI response:", formattedResponse); 
          updateAssistantState({
            chatMessages: [
              ...chatMessages,
              { 
                ...formattedResponse, 
                sender: 'ai', 
                model: selectedModel,
                extractedConcepts: formattedResponse.related_concepts,
                pendingUpdates: formattedResponse.pending_updates
              },
            ],
            workflowProgress: '',
            progressMessages: [],
          });
        })
        .catch(error => {
          console.error("Failed to fetch AI result", error);
          updateAssistantState({
            chatMessages: [
              ...chatMessages,
              { text: "Failed to fetch AI result", sender: 'system', type: 'error' },
            ],
          });
        });
    }
    updateAssistantState({ userInput: '' });
    setAiProcessId(null);
  }, [aiProcessId, chatMessages, selectedModel, updateAssistantState]);

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', p: 2, fontSize: '1.5rem' }}>
        AI Assistant
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DashboardCard sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <TopBar>
              <Tooltip title="Clear and reset all AI Assistant data">
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleResetAssistant}
                >
                  Reset Assistant
                </StyledButton>
              </Tooltip>
              <AIModelToggle 
                selectedModel={selectedModel} 
                setSelectedModel={(model) => updateAssistantState({ selectedModel: model })} 
              />
            </TopBar>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <TopHalf>
                <SelectedResearch
                  selectedPdfs={selectedPdfs}
                />
              </TopHalf>
              <BottomHalf>
                <SystemInstructionsBox>
                  <SystemInstructionsHeader
                    onClick={() => setSystemInstructionsOpen(!systemInstructionsOpen)}
                    endIcon={systemInstructionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  >
                    AI Persona
                  </SystemInstructionsHeader>
                  <Collapse in={systemInstructionsOpen}>
                    <Box sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.03)' }}>
                      <StyledTextField
                        fullWidth
                        multiline
                        variant="outlined"
                        placeholder="Define AI behavior, expertise, and output style (e.g., 'You are an AI expert in financial analysis, focusing on quantitative insights and data-driven recommendations.')"
                        value={systemPrompt}
                        onChange={(e) => updateAssistantState({ systemPrompt: e.target.value })}
                        sx={{ fontSize: '0.875rem' }}
                      />
                    </Box>
                  </Collapse>
                </SystemInstructionsBox>
                <UserInputBox>
                  <StyledTextField
                    fullWidth
                    multiline
                    variant="outlined"
                    placeholder="Enter your question here..."
                    value={userInput}
                    onChange={(e) => updateAssistantState({ userInput: e.target.value })}
                    InputProps={{
                      style: { height: '100%', overflowY: 'auto' }
                    }}
                  />
                  <Tooltip title={isRunning ? "Stop" : "Send"}>
                    <ActionButton
                      variant="contained"
                      color={isRunning ? "secondary" : "primary"}
                      onClick={handleSendMessage}
                      disabled={!userInput.trim()}
                    >
                      {isRunning ? <StopIcon /> : <SendIcon />}
                    </ActionButton>
                  </Tooltip>
                </UserInputBox>
              </BottomHalf>
            </Box>
          </DashboardCard>
        </Grid>


        <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DashboardCard sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <TopBar>
              <Tooltip title="Clear the conversation history">
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleResetConversation}
                >
                  Reset Conversation
                </StyledButton>
              </Tooltip>
              <ToggleButton
                variant="outlined"
                color="primary"
                onClick={() => setShowOntologyInsights(!showOntologyInsights)}
              >
                {showOntologyInsights ? 'Hide Insights' : 'Show Insights'}
              </ToggleButton>
            </TopBar>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {isRunning && aiProcessId && (
                <ProgressUpdates 
                  processId={aiProcessId}
                  onComplete={handleAiComplete}
                  endpoint="ai/progress"
                />
              )}
              <AIResponse
                chatMessages={chatMessages}
                isLoading={isLoading}
                showOntologyInsights={showOntologyInsights}
              />
            </Box>
          </DashboardCard>
        </Grid>


      </Grid>
    </Box>
  );
};

export default React.memo(Assistant);