// PromptGuidance.js

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Button,
  TextField,
  Tooltip,
  IconButton,
  Fade,
  Grid,
  Chip  
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import api from './api';

const DashboardCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  '&:hover': {
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.6)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: 'rgba(52, 152, 219, 0.2)',
  color: '#ecf0f1',
  border: '1px solid rgba(52, 152, 219, 0.5)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(52, 152, 219, 0.4)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
}));

const AnimatedAccordion = motion(Accordion);

const PromptGuidance = () => {
  const [expanded, setExpanded] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [promptFeedback, setPromptFeedback] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');

  useEffect(() => {
    const fetchSystemPrompt = async () => {
      try {
        const response = await api.get('/api/system/system-prompt');
        setSystemPrompt(response.data.systemPrompt);
      } catch (error) {
        console.error('Failed to fetch system prompt:', error);
      }
    };
    fetchSystemPrompt();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const analyzePrompt = () => {
    // This is a placeholder for more advanced prompt analysis
    const wordCount = userPrompt.split(/\s+/).length;
    const containsFinancialTerms = /EBITDA|revenue|EPS|valuation/i.test(userPrompt);
    const isStructured = /\d+\.|\*|-/.test(userPrompt);

    let feedback = "Prompt Analysis:\n";
    feedback += `- Word count: ${wordCount} (Ideal: 50-150 words)\n`;
    feedback += `- Contains financial terms: ${containsFinancialTerms ? 'Yes' : 'No'}\n`;
    feedback += `- Structured format: ${isStructured ? 'Yes' : 'No'}\n`;
    feedback += "\nSuggestions:\n";
    if (wordCount < 50) feedback += "- Consider adding more detail to your prompt\n";
    if (!containsFinancialTerms) feedback += "- Include specific financial terms to leverage the ontology\n";
    if (!isStructured) feedback += "- Structure your prompt with numbered points or bullet points\n";

    setPromptFeedback(feedback);
  };

  const promptStructure = [
    { label: 'PDF Content', description: 'Extracted and processed text from selected PDFs' },
    { label: 'Ontology Enrichment', description: 'Domain-specific concepts and relationships added' },
    { label: 'Related Concepts', description: 'Additional context from the financial ontology' },
    { label: 'User Input', description: 'The query or instruction provided by the user' },
  ];

  const optimizationStrategies = [
    'Be specific and focused on the content of the selected PDFs',
    'Use domain-specific financial terminology to trigger ontology concepts',
    'Structure multi-part queries clearly with numbered points',
    'Request comparative analysis across reports and time periods',
    'Specify desired output formats (e.g., bullet points, tables, charts)',
    'Ask for trend analysis and synthesis of information across multiple reports',
    'Consider token limits and chunk appropriately for complex queries',
    'Use time-based queries for temporal analysis of financial data',
    'Encourage critical analysis, bias identification, and contrarian viewpoints',
    'Leverage the system\'s ability to process multiple documents simultaneously',
    'Include requests for contextual explanations of technical terms',
    'Prioritize information by specifying the most crucial aspects to focus on',
    'Request identification of potential biases or gaps in the research reports',
  ];

  const useCases = [
    {
      title: 'Quarterly Results Summary',
      example: `Provide a comprehensive quarterly results summary for NVIDIA based on the attached reports. Include:
1. Key financial metrics: revenue, net income, EPS, and segment-specific revenues.
2. Year-over-year and quarter-over-quarter comparisons with percentage changes.
3. Performance against analyst expectations, highlighting any significant beats or misses.
4. Changes in operating margins, gross margins, and other profitability indicators.
5. Main factors contributing to performance, including market conditions and company-specific events.
6. Overview of analyst reactions, including sentiment changes and notable quote.
7. Forward guidance provided by the company and analyst interpretations.
8. Any changes in analyst recommendations or price targets following the results.
9. Key operational metrics specific to NVIDIA (e.g., data center revenue, gaming segment performance).
10. Brief comparison to main competitors' performance in the same quarter, if available.

Please structure the response with clear headings for each section, use bullet points for easy readability, and include a brief executive summary at the beginning highlighting the most impactful points.`
    },
    {
      title: 'Actual Results vs. Forecasts',
      example: `Compare Tesla, Inc.'s actual quarterly results with forecasts in the attached analyst reports:

1. Quantitative Comparison:
   a) Create a table comparing actual results with consensus forecasts for key metrics:
      - Revenue
      - EBITDA
      - Net Income
      - EPS (GAAP and Non-GAAP)
      - Free Cash Flow
      - Vehicle Deliveries (Total and by model)
      - Gross Margin (Overall and Automotive)
   b) Calculate the percentage difference between actual results and forecasts for each metric.
   c) Highlight metrics that deviated significantly (>5%) from consensus.

2. Forecast Accuracy:
   a) Identify which analyst(s) had the most accurate forecasts and in which areas.
   b) Highlight any metrics where actual results significantly deviated from all forecasts (>10% difference).
   c) Analyze the trend of forecast accuracy over the past 4 quarters, if data is available.

3. Reasons for Discrepancies:
   a) Summarize the main factors cited in the reports for significant differences between actual results and forecasts.
   b) Note any unexpected events or market conditions that impacted the results.
   c) Discuss any company-specific factors (e.g., production issues, new product launches) that affected the results.

4. Analyst Revisions:
   a) Detail changes analysts have made to their future forecasts or price targets based on these results.
   b) Summarize the reasons given for these revisions.
   c) Identify any notable shifts in long-term projections or company valuation models.

5. Impact on Outlook:
   a) Describe how the actual results have affected the overall sentiment and outlook for Tesla in the short and medium term.
   b) Note any changes in analyst recommendations (e.g., Buy, Hold, Sell) following the results.
   c) Discuss how these results might impact Tesla's competitive position in the EV market.

6. Key Takeaways:
   a) Summarize the 3-5 most important insights from this comparison.
   b) Highlight any recurring themes or concerns across multiple analyst reports.

Please provide a concise executive summary (250 words max) at the beginning, followed by the detailed analysis structured as outlined above. Use tables and bullet points where appropriate to enhance readability.`
    },
    {
      title: 'Recent Highlights and Sentiment',
      example: `Analyze recent highlights and sentiment for NVIDIA Corporation from the last three months:

1. Key Company Updates:
   a) List the top 5 most significant company-specific news or events mentioned across the reports.
   b) For each, provide a brief description and explain its importance to NVIDIA's business or stock performance.
   c) Rate each event's impact on a scale of 1-5 (1 being minimal, 5 being highly significant).

2. Industry Trends:
   a) Identify 3-5 major industry trends or market shifts that are impacting NVIDIA.
   b) Explain how analysts believe these trends will affect NVIDIA's future performance.
   c) Compare NVIDIA's positioning relative to competitors in light of these trends.

3. Product and Technology Developments:
   a) Summarize notable product launches, R&D breakthroughs, or technological advancements.
   b) Highlight analyst opinions on how these developments position NVIDIA against its competitors.
   c) Discuss any potential new markets or applications opened up by these developments.

4. Financial Performance Highlights:
   a) Note recent financial results or guidance that have significantly influenced analyst views.
   b) Compare these to previous expectations and explain any major discrepancies.
   c) Highlight key financial metrics that analysts are focusing on and explain why.

5. Partnerships and Strategic Moves:
   a) Detail any new partnerships, acquisitions, or strategic initiatives.
   b) Summarize analyst thoughts on the potential impact of these moves.
   c) Discuss how these strategies align with NVIDIAs long-term goals and market positioning.

6. Sentiment Analysis:
   a) Provide an overall assessment of analyst sentiment towards NVIDIA (bullish, bearish, or neutral).
   b) Highlight notable changes in sentiment over the past three months and the reasons for these changes.
   c) Create a sentiment timeline showing how key events correlate with changes in analyst sentiment.

7. Risk Assessment:
   a) List the top 3-5 risks or challenges for NVIDIA identified in recent reports.
   b) Explain how analysts believe these risks might impact NVIDIAs future performance.
   c) Discuss any mitigation strategies mentioned or suggested by analysts.

8. Growth Projections:
   a) Summarize changes in growth projections or price targets.
   b) Explain the key factors driving these changes.
   c) Provide a range of growth estimates across different analysts and explain any significant discrepancies.

9. Consensus Outlook:
   a) Provide a brief consensus outlook for NVIDIA based on the analyzed reports.
   b) Highlight any significant disagreements among analysts, if present.
   c) Identify any potential blind spots or areas of uncertainty in the consensus view.

10. ESG Considerations:
    a) Summarize any significant ESG (Environmental, Social, Governance) factors mentioned in the reports.
    b) Discuss how these factors are influencing analyst perceptions and valuations.

Please structure the response with clear headings and subheadings. Use bullet points for lists and provide a brief executive summary (300 words max) at the beginning that captures the most crucial points, overall sentiment, and any particularly insightful or unique perspectives found in the reports. Include a "Key Metrics at a Glance" section with important financial and operational metrics. Where possible, use comparative data to show NVIDIA's position relative to key competitors or industry benchmarks.`
    }
  ];

  const advancedTechniques = [
    {
      title: 'Chain-of-Thought Prompting',
      description: 'Guide the AI through a step-by-step reasoning process to arrive at more accurate and detailed analyses.',
      example: 'First, summarize the key financial metrics. Then, compare these to industry averages. Next, analyze the implications for future growth. Finally, synthesize this information into an overall assessment.'
    },
    {
      title: 'Few-Shot Learning',
      description: 'Provide a few examples of the desired output format or analysis style to guide the AIs response.',
      example: 'Here are two examples of how to analyze a companys competitive position: [Example 1] [Example 2]. Now, using this format, analyze NVIDIAs competitive position based on the provided reports.'
    },
    {
      title: 'Persona-based Prompting',
      description: 'Frame the prompt from the perspective of a specific financial role or expertise level.',
      example: 'As a senior financial analyst with 15 years of experience in the tech sector, provide an in-depth analysis of Apples latest quarterly results and their implications for the companys long-term strategy.'
    },
    {
      title: 'Contrarian Analysis Requests',
      description: 'Explicitly ask for alternative viewpoints or potential flaws in prevailing analyses.',
      example: 'Most analysts are bullish on Teslas growth prospects. Identify and elaborate on three potential scenarios that could significantly hinder Teslas projected growth trajectory.'
    },
    {
      title: 'Multi-Perspective Analysis',
      description: 'Request analysis from multiple viewpoints to gain a more comprehensive understanding.',
      example: 'Analyze Amazons recent acquisition from three perspectives: 1) a retail industry analyst, 2) a cloud computing expert, and 3) an antitrust regulatory specialist.'
    }
  ];

  return (
    <Box sx={{ flexGrow: 1, m: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 4 }}>
        Advanced Prompt Guidance
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'secondary.main' }}>
              System Prompt
            </Typography>
            <Typography variant="body2" paragraph>
              A system prompt is a set of instructions that defines the AI's role, capabilities, and behavior. It acts as a foundation for all interactions, guiding the AI's responses to align with specific goals and constraints. In LGT Research AI, the system prompt enhances the AI's ability to analyze financial research effectively.
            </Typography>
            <Typography variant="body2" paragraph>
              Key benefits of using system prompts in LGT Research AI:
            </Typography>
            <ul>
              <li>Consistent behavior across interactions</li>
              <li>Tailored responses for financial analysis</li>
              <li>Enhanced understanding of complex financial concepts</li>
              <li>Improved ability to synthesize information from multiple sources</li>
              <li>Better alignment with ethical and compliance guidelines</li>
            </ul>
            <Typography variant="body2" paragraph>
              To leverage system prompts effectively:
            </Typography>
            <ol>
              <li>Familiarize yourself with the current system prompt to understand the AI's capabilities</li>
              <li>Frame your queries in a way that aligns with the system prompt's instructions</li>
              <li>Use domain-specific terminology to trigger the AI's financial expertise</li>
              <li>Request multi-faceted analysis that utilizes the AI's diverse capabilities</li>
            </ol>
            <TextField
              fullWidth
              multiline
              rows={10}
              variant="outlined"
              value={systemPrompt}
              InputProps={{
                readOnly: true,
              }}
              sx={{ mb: 2, backgroundColor: 'rgba(255,255,255,0.05)' }}
            />
          </DashboardCard>
        </Grid>

        <Grid item xs={12}>
          <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'secondary.main' }}>
              Understanding the Prompt Structure
            </Typography>
            <Grid container spacing={2}>
              {promptStructure.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box>
                    <Typography variant="subtitle1" color="primary">{item.label}</Typography>
                    <Typography variant="body2">{item.description}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DashboardCard>
        </Grid>

        <Grid item xs={12}>
          <AnimatedAccordion 
            expanded={expanded === 'panel1'} 
            onChange={handleChange('panel1')}
            initial={false}
            animate={{ backgroundColor: expanded === 'panel1' ? 'rgba(255,255,255,0.05)' : 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Optimization Strategies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {optimizationStrategies.map((strategy, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography variant="body2">• {strategy}</Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </AnimatedAccordion>
        </Grid>

        <Grid item xs={12}>
          <AnimatedAccordion 
            expanded={expanded === 'panel2'} 
            onChange={handleChange('panel2')}
            initial={false}
            animate={{ backgroundColor: expanded === 'panel2' ? 'rgba(255,255,255,0.05)' : 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Use Case Examples</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {useCases.map((useCase, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      {useCase.title}
                      <Tooltip title="Copy example">
                        <IconButton size="small" onClick={() => copyToClipboard(useCase.example)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', backgroundColor: 'rgba(0,0,0,0.1)', padding: 2, borderRadius: 1 }}>
                      {useCase.example}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </AnimatedAccordion>
        </Grid>

        <Grid item xs={12}>
          <AnimatedAccordion 
            expanded={expanded === 'panel3'} 
            onChange={handleChange('panel3')}
            initial={false}
            animate={{ backgroundColor: expanded === 'panel3' ? 'rgba(255,255,255,0.05)' : 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Advanced Prompting Techniques</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {advancedTechniques.map((technique, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      {technique.title}
                      <Tooltip title={technique.description}>
                        <IconButton size="small">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {technique.description}
                    </Typography>
                    <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap', backgroundColor: 'rgba(0,0,0,0.1)', padding: 2, borderRadius: 1 }}>
                      Example: {technique.example}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </AnimatedAccordion>
        </Grid>

        <Grid item xs={12}>
          <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'secondary.main' }}>
              Prompt Crafting Workshop
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Enter your prompt here..."
              value={userPrompt}
              onChange={(e) => setUserPrompt(e.target.value)}
              sx={{ mb: 2, backgroundColor: 'rgba(255,255,255,0.05)' }}
            />
            <Button variant="contained" color="primary" onClick={analyzePrompt}>
              Analyze Prompt
            </Button>
            <Fade in={!!promptFeedback}>
              <Typography variant="body2" component="pre" sx={{ mt: 2, whiteSpace: 'pre-wrap', backgroundColor: 'rgba(0,0,0,0.1)', padding: 2, borderRadius: 1 }}>
                {promptFeedback}
              </Typography>
            </Fade>
          </DashboardCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromptGuidance;