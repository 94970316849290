import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import {
  Box, Grid, Typography, Modal, IconButton, Tooltip,
  Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Select, MenuItem,
  Snackbar, Alert, LinearProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ListViewIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PdfCard from './PdfCard';
import { Document, Page } from 'react-pdf';
import { AppContext } from './AppContext';
import { motion } from 'framer-motion';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DashboardCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  '&:hover': {
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.6)',
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: 'none',
  maxWidth: '90%',
  maxHeight: '90vh',
  overflow: 'auto',
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[25],
  },
  '& .react-pdf__Document': {
    display: 'flex',
    justifyContent: 'center',
    '& .react-pdf__Page': {
      boxShadow: theme.shadows[8],
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const Research = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [tokenCounts, setTokenCounts] = useState({});
  const [pdfError, setPdfError] = useState(null);
  
  const { refreshResearch } = useContext(AppContext);
  
  const memoizedData = useMemo(() => data, [data]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        page: currentPage,
        per_page: itemsPerPage,
        model: 'gpt-4o' // default model
      });
      const url = `/api/data/research?${params.toString()}`;
      const response = await api.get(url);
  
      setData(response.data.data);
      setTotalPages(response.data.total_pages);
      setTotalFiles(response.data.total_files || 0);
      
      // Update token counts
      const newTokenCounts = {};
      response.data.data.forEach(file => {
        newTokenCounts[file.filename] = file.token_count;
      });
      setTokenCounts(newTokenCounts);
    } catch (error) {
      console.error("Failed to fetch data", error);
      setData([]);
      setTokenCounts({});
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData, currentPage, itemsPerPage, refreshResearch]);

  const handleCardClick = useCallback((file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
    setPageNumber(1);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedFile(null);
  }, []);

  const handleDownload = useCallback(() => {
    if (selectedFile) {
      const downloadUrl = `/api/data/download/${encodeURIComponent(selectedFile.filename)}`;
      window.open(downloadUrl, '_blank');
    }
  }, [selectedFile]);

  const handlePassToAssistant = useCallback((files) => {
    const pdfsToPass = Array.isArray(files) ? files : [files];
    navigate('/assistant', { state: { pdfsToAdd: pdfsToPass } });
  }, [navigate]);

  const handleSelectFile = useCallback((file) => {
    setSelectedFiles((prevSelected) => {
      if (prevSelected.some(f => f.filename === file.filename)) {
        return prevSelected.filter(f => f.filename !== file.filename);
      } else {
        return [...prevSelected, file];
      }
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedFiles((prevSelected) => 
      prevSelected.length === memoizedData.length ? [] : [...memoizedData]
    );
  }, [memoizedData]);

  const handleDeletePdf = useCallback(async (file) => {
    if (window.confirm(`Are you sure you want to permanently delete ${file.filename} from the database?`)) {
      try {
        await api.delete(`/api/data/delete/${encodeURIComponent(file.filename)}`);
        fetchData();
        handleCloseModal();
        setSnackbarMessage(`Successfully deleted ${file.filename}`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to delete PDF:', error);
        setSnackbarMessage('Failed to delete PDF. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  }, [fetchData, handleCloseModal]);

  const handleDeleteSelected = useCallback(async () => {
    if (window.confirm(`Are you sure you want to permanently delete ${selectedFiles.length} selected file(s) from the database?`)) {
      setIsLoading(true);
      try {
        const deletePromises = selectedFiles.map(file => 
          api.delete(`/api/data/delete/${encodeURIComponent(file.filename)}`)
        );
        await Promise.all(deletePromises);
        setSnackbarMessage(`Successfully deleted ${selectedFiles.length} file(s)`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setSelectedFiles([]);
        fetchData();
      } catch (error) {
        console.error('Failed to delete PDFs:', error);
        setSnackbarMessage('Failed to delete selected files. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedFiles, fetchData]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPdfError(null);
  }, []);

  const onDocumentLoadError = useCallback((error) => {
    console.error('Error loading PDF:', error);
    setPdfError('Failed to load PDF. Please try again.');
  }, []);

  const goToPrevPage = useCallback(() => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  }, []);

  const goToNextPage = useCallback(() => {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  }, [numPages]);

  const renderPdfCards = useMemo(() => {
    if (memoizedData.length === 0) {
      return null;
    }
    return memoizedData.map((file) => (
      <Grid item xs={12} sm={6} md={4} key={file.filename}>
        <DashboardCard
          whileHover={{ scale: 1.01 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <PdfCard
            pdf={file}
            onClick={handleCardClick}
            onSelect={handleSelectFile}
            isSelected={selectedFiles.some(f => f.filename === file.filename)}
          />
        </DashboardCard>
      </Grid>
    ));
  }, [memoizedData, handleCardClick, handleSelectFile, selectedFiles]);

  const renderPdfTableRows = useMemo(() => {
    return memoizedData.map((file) => (
      <TableRow key={file.filename} onClick={() => handleCardClick(file)} style={{ cursor: 'pointer', display: 'flex' }}>
        <TableCell style={{ flex: '1 1 5%' }}>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            handleSelectFile(file);
          }}>
            {selectedFiles.some(f => f.filename === file.filename) ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ flex: '1 1 15%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.filename}</TableCell>
        <TableCell style={{ flex: '1 1 15%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.title || 'Untitled'}</TableCell>
        <TableCell style={{ flex: '1 1 13%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.reportDate || 'N/A'}</TableCell>
        <TableCell style={{ flex: '1 1 15%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.authors || 'Unknown'}</TableCell>
        <TableCell style={{ flex: '3 1 25%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.companyNames || 'N/A'}</TableCell>
        <TableCell style={{ flex: '3 1 25%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.keyTopics || 'N/A'}</TableCell>
        <TableCell style={{ flex: '1 1 5%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.pageCount || 0}</TableCell>
        <TableCell style={{ flex: '1 1 10%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.token_count?.toLocaleString() || 'N/A'}</TableCell>
        <TableCell style={{ flex: '1 1 13%', wordBreak: 'break-all', overflowWrap: 'break-word' }}>{file.uploadDate ? format(new Date(file.uploadDate), 'MMM d, yyyy') : 'N/A'}</TableCell>
      </TableRow>
    ));
  }, [memoizedData, handleCardClick, handleSelectFile, selectedFiles, tokenCounts]);
 
  return (
    <Box sx={{ flexGrow: 1, m: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 4 }}>
        Research
      </Typography>

      {isLoading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress />
        </Box>
      )}
      <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ActionButton variant="contained" color="secondary" onClick={handleSelectAll}>
              {selectedFiles.length === memoizedData.length ? 'Deselect All' : 'Select All'}
            </ActionButton>
            <Tooltip title="Pass to Assistant" placement="top" arrow>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={() => handlePassToAssistant(selectedFiles)}
                startIcon={<SendIcon />}
                disabled={selectedFiles.length === 0}
              >
                Pass to Assistant
              </ActionButton>
            </Tooltip>
            <Tooltip title="Delete Selected" placement="top" arrow>
              <ActionButton
                variant="contained"
                color="error"
                onClick={handleDeleteSelected}
                startIcon={<DeleteIcon />}
                disabled={selectedFiles.length === 0}
              >
                Delete Selected
              </ActionButton>
            </Tooltip>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
            startIcon={viewMode === 'grid' ? <ListViewIcon /> : <GridViewIcon />}
          >
            {viewMode === 'grid' ? 'List View' : 'Grid View'}
          </Button>
        </Box>
      </DashboardCard>
      <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
        {viewMode === 'grid' ? (
          <Grid container spacing={2}>
            {renderPdfCards}
          </Grid>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ display: 'flex' }}>
                  <TableCell style={{ flex: '1 1 5%' }}>Select</TableCell>
                  <TableCell style={{ flex: '1 1 15%' }}>Filename</TableCell>
                  <TableCell style={{ flex: '1 1 15%' }}>Report Title</TableCell>
                  <TableCell style={{ flex: '1 1 13%' }}>Report Date</TableCell>
                  <TableCell style={{ flex: '1 1 15%' }}>Author(s)</TableCell>
                  <TableCell style={{ flex: '3 1 25%' }}>Company Name(s)</TableCell>
                  <TableCell style={{ flex: '3 1 25%' }}>Key Topics</TableCell>
                  <TableCell style={{ flex: '1 1 5%' }}>Page Count</TableCell>
                  <TableCell style={{ flex: '1 1 10%' }}>Token Count</TableCell>
                  <TableCell style={{ flex: '1 1 13%' }}>Uploaded Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderPdfTableRows}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DashboardCard>
      <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={itemsPerPage}
              onChange={(event) => setItemsPerPage(event.target.value)}
              sx={{ marginRight: 2 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
          </Box>
          <Typography variant="body1">
            Total PDFs: {totalFiles.toLocaleString()}
          </Typography>
          <Box>
            <Button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage <= 1}>
              Previous
            </Button>
            <Button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage >= totalPages}>
              Next
            </Button>
          </Box>
        </Box>
      </DashboardCard>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedFile && (
            <>
              <Typography variant="h5" gutterBottom>{selectedFile.filename}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, height: '70vh' }}>
                <Document
                  file={`/api/data/download/${encodeURIComponent(selectedFile.filename)}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) => console.error('Error loading PDF:', error)}
                  loading={<LinearProgress />}
                >
                  {pdfError ? (
                    <Typography color="error">{pdfError}</Typography>
                  ) : (
                    <Page pageNumber={pageNumber} height={600} renderTextLayer={false} renderAnnotationLayer={false} />
                  )}
                </Document>
                </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body2">
                  Page {pageNumber} of {numPages}
                </Typography>
                <Box>
                  <Button onClick={goToPrevPage} disabled={pageNumber === 1}>
                    Prev
                  </Button>
                  <Button onClick={goToNextPage} disabled={pageNumber === numPages}>
                    Next
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Tooltip title="Download PDF" placement="top" arrow>
                  <IconButton onClick={handleDownload}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Pass to Assistant" placement="top" arrow>
                  <IconButton onClick={() => handlePassToAssistant(selectedFile)}>
                    <SendIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete PDF" placement="top" arrow>
                  <IconButton onClick={() => handleDeletePdf(selectedFile)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </ModalContent>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default React.memo(Research);
