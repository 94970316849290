// frontend/src/ModelConfig.js

import React, { useState, useEffect } from 'react';
import api from './api';
import {
  Box,
  Typography,
  Slider,
  TextField,
  Button,
  Collapse,
  Snackbar,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const ConfigCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  '&:hover': {
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.6)',
  },
}));

const ParamSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ModelConfig = () => {
  const [configs, setConfigs] = useState({
    "gpt-4o": { max_tokens: 4096, temperature: 0, top_p: 0.95, context_window: 128000 },
    "gpt-4-turbo": { max_tokens: 4096, temperature: 0, top_p: 0.95, context_window: 128000 },
    "gpt-3-5": { max_tokens: 4096, temperature: 0, top_p: 0.95, context_window: 16385 }
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const response = await api.get('/api/system/model-config');
      const fetchedConfigs = response.data;
      // Ensure each model has all required fields
      Object.keys(fetchedConfigs).forEach(model => {
        fetchedConfigs[model] = {
          max_tokens: fetchedConfigs[model].max_tokens || 4096,
          temperature: fetchedConfigs[model].temperature || 0,
          top_p: fetchedConfigs[model].top_p || 0.95,
          context_window: fetchedConfigs[model].context_window || (model.startsWith('gpt-4') ? 128000 : 16385)
        };
      });
      setConfigs(fetchedConfigs);
    } catch (error) {
      console.error('Failed to fetch model configs:', error);
      setSnackbar({ open: true, message: 'Failed to fetch model configurations', severity: 'error' });
    }
  };

  const handleConfigChange = (model, param) => (event, newValue) => {
    setConfigs(prevConfigs => ({
      ...prevConfigs,
      [model]: { ...prevConfigs[model], [param]: newValue }
    }));
  };

  const handleInputChange = (model, param) => (event) => {
    const value = event.target.value === '' ? '' : Number(event.target.value);
    setConfigs(prevConfigs => ({
      ...prevConfigs,
      [model]: { ...prevConfigs[model], [param]: value }
    }));
  };

  const saveConfig = async (model) => {
    try {
      await api.post('/api/system/update_model_config', {
        model_name: model,
        ...configs[model]
      });
      setSnackbar({ open: true, message: `Configuration for ${model} saved successfully`, severity: 'success' });
    } catch (error) {
      console.error(`Failed to save model config for ${model}:`, error);
      setSnackbar({ open: true, message: `Failed to save configuration for ${model}`, severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleExpandClick = (model) => {
    setExpanded(prev => ({ ...prev, [model]: !prev[model] }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', mb: 3, pt: 2 }}>
        Model Configurations
      </Typography>
      {Object.entries(configs).reverse().map(([model, params]) => (
        <ConfigCard key={model} whileHover={{ scale: 1.02 }} transition={{ type: 'spring', stiffness: 300 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" onClick={() => handleExpandClick(model)} sx={{ cursor: 'pointer' }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>{model}</Typography>
            <ExpandMore expand={expanded[model]} />
          </Box>
          <Collapse in={expanded[model]} timeout="auto" unmountOnExit>
            {Object.entries(params).map(([param, value]) => (
              <Box key={param} mt={2}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>{param}</Typography>
                <Box display="flex" alignItems="center">
                  <ParamSlider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleConfigChange(model, param)}
                    aria-labelledby={`${model}-${param}-slider`}
                    valueLabelDisplay="auto"
                    step={param === 'temperature' || param === 'top_p' ? 0.01 : 1}
                    marks
                    min={0}
                    max={param === 'max_tokens' ? 4096 : param === 'temperature' ? 1 : param === 'context_window' ? 128000 : 1}
                    sx={{ flexGrow: 1, mr: 2 }}
                  />
                  <TextField
                    value={value}
                    onChange={handleInputChange(model, param)}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: param === 'temperature' || param === 'top_p' ? 0.01 : 1,
                      min: 0,
                      max: param === 'max_tokens' ? 4096 : param === 'temperature' ? 1 : param === 'context_window' ? 128000 : 1,
                    }}
                    sx={{ width: 100 }}
                  />
                </Box>
              </Box>
            ))}
            <Button onClick={() => saveConfig(model)} variant="contained" color="primary" sx={{ mt: 2 }}>
              Save {model} Configuration
            </Button>
          </Collapse>
        </ConfigCard>
      ))}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ModelConfig;