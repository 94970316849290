import React, { useState, useEffect, useMemo } from 'react';
import api from './api';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  TimeScale,
} from 'chart.js';
import { Scatter, Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import ErrorBoundary from './ErrorBoundary';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  TimeScale
);

const DashboardCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(145deg, #1e2a3a 0%, #2a3f55 100%)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  '&:hover': {
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.6)',
  },
}));

const MetricValue = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const FloatingSidebar = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(2),
  top: '50%',
  transform: 'translateY(-50%)',
  background: 'rgba(30, 42, 58, 0.8)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  backdropFilter: 'blur(5px)',
  transition: 'opacity 0.3s ease-in-out',
  opacity: 1,
  pointerEvents: 'auto',
}));

const System = () => {
  const [metrics, setMetrics] = useState({
    averageResponseTime: 0,
    averageAIResponseTime: 0,
    apiCallsCount: 0,
    aiApiCallsCount: 0,
    aiResponseTimeByModel: {},
    aiCallsCountByModel: {},
    ontologyMetrics: [],
    pendingOntologyUpdates: [],
    ontologyUpdateHistory: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartKey, setChartKey] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);

  const fetchData = async () => {
    try {
      const dashboardMetricsResponse = await api.get('/api/system/dashboard_metrics');
      setMetrics(dashboardMetricsResponse.data);
      setChartKey(prev => prev + 1);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowSidebar(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const modelColors = useMemo(() => ({
    'gpt-4o': 'rgba(75, 192, 192, 1)',
    'gpt-4-turbo': 'rgba(255, 159, 64, 1)',
    'gpt-3-5': 'rgba(255, 99, 132, 1)',
  }), []);

  const aiResponseTimesChartData = useMemo(() => ({
    datasets: Object.entries(metrics.aiResponseTimeByModel).map(([model, data]) => ({
      label: model,
      data: data.map(item => ({
        x: new Date(item.timestamp),
        y: item.responseTime
      })),
      backgroundColor: modelColors[model],
      pointRadius: 3,
      pointHoverRadius: 5,
    })),
  }), [metrics.aiResponseTimeByModel, modelColors]);

  const scatterOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'rgba(255, 255, 255, 0.87)',
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: 'AI API Response Times',
        color: 'rgba(255, 255, 255, 0.87)',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const point = context.raw;
            return `${context.dataset.label}: ${point.y.toFixed(3)}s on ${new Date(point.x).toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            minute: 'MMM d'
          },
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Response Time (seconds)',
        },
        ticks: {
          callback: (value) => `${value.toFixed(2)}s`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const point = context.raw;
            return `${context.dataset.label}: ${point.y.toFixed(3)}s at ${new Date(point.x).toLocaleString()}`;
          },
        },
      },
    },
  }), []);

  return (
    <ErrorBoundary>
      <Box sx={{ flexGrow: 1, m: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 4 }}>  
          System Dashboard
        </Typography>

        {error ? (
          <Box sx={{ m: 4 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        ) : loading ? (
          <LinearProgress />
        ) : (
          <>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={3}>
                <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <Typography variant="h6" gutterBottom>Average API Response Time</Typography>
                  <MetricValue>{metrics.averageResponseTime.toFixed(3)} s</MetricValue>
                </DashboardCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <Typography variant="h6" gutterBottom>Number of API Calls</Typography>
                  <MetricValue>{metrics.apiCallsCount.toLocaleString()}</MetricValue>
                </DashboardCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <Typography variant="h6" gutterBottom>Average AI API Response Time</Typography>
                  <MetricValue>{metrics.averageAIResponseTime.toFixed(3)} s</MetricValue>
                </DashboardCard>
              </Grid>
              <Grid item xs={12} md={3}>
                <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <Typography variant="h6" gutterBottom>Number of AI API Calls</Typography>
                  <MetricValue>{metrics.aiApiCallsCount.toLocaleString()}</MetricValue>
                </DashboardCard>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3 }} justifyContent="center">
              {Object.entries(metrics.aiResponseTimeByModel).map(([model, data]) => {
                const averageTime = data.length > 0 ? data.reduce((sum, item) => sum + item.responseTime, 0) / data.length : 0;
                return (
                  <Grid item xs={12} md={4} key={model}>
                    <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                      <Typography variant="h6" gutterBottom>{model}</Typography>
                      <MetricValue>{averageTime.toFixed(3)} s</MetricValue>
                      <Typography variant="body2">Calls: {metrics.aiCallsCountByModel[model] || 0}</Typography>
                    </DashboardCard>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3 }} justifyContent="center">
              <Grid item xs={12}>
                <DashboardCard whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 300 }}>
                  <Typography variant="h6" gutterBottom>AI API Response Times</Typography>
                  <Box sx={{ height: '500px' }}>
                    <Scatter data={aiResponseTimesChartData} options={scatterOptions} />
                  </Box>
                </DashboardCard>
              </Grid>
            </Grid>
          </>
        )}
        <FloatingSidebar style={{ opacity: showSidebar ? 1 : 0, pointerEvents: showSidebar ? 'auto' : 'none' }}>
          <Typography variant="subtitle2">Avg API Response: {metrics.averageResponseTime.toFixed(3)}s</Typography>
          <Typography variant="subtitle2">API Calls: {metrics.apiCallsCount.toLocaleString()}</Typography>
          <Typography variant="subtitle2">Avg AI Response: {metrics.averageAIResponseTime.toFixed(3)}s</Typography>
          <Typography variant="subtitle2">AI Calls: {metrics.aiApiCallsCount.toLocaleString()}</Typography>
        </FloatingSidebar>
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(System);