// AIModelToggle.js
import React from 'react';
import { Select, MenuItem, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BoltIcon from '@mui/icons-material/Bolt';

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 150,
  height: 36,
  '& .MuiSelect-select': {
    paddingY: theme.spacing(0.5),
    paddingX: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
}));

const ModelIcon = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  display: 'inline-flex',
  '& svg': {
    fontSize: '1rem',
  },
}));

const modelInfo = {
  'gpt-4o': {
    name: 'GPT-4o',
    description: 'Most capable model, best for complex tasks',
    tokenLimit: 128000,
    icon: <AutoAwesomeIcon />,
  },
  'gpt-4-turbo': {
    name: 'GPT-4 Turbo',
    description: 'Faster version of GPT-4, good balance of speed and capability',
    tokenLimit: 128000,
    icon: <RocketLaunchIcon />,
  },
  'gpt-3-5': {
    name: 'GPT-3.5 Turbo',
    description: 'Fast and cost-effective, good for most tasks',
    tokenLimit: 16385,
    icon: <BoltIcon />,
  },
};

const AIModelToggle = ({ selectedModel, setSelectedModel }) => {
  return (
    <StyledSelect
      value={selectedModel}
      onChange={(event) => setSelectedModel(event.target.value)}
      displayEmpty
      inputProps={{ 'aria-label': 'Select AI model' }}
    >
      {Object.entries(modelInfo).map(([modelKey, modelData]) => (
        <MenuItem key={modelKey} value={modelKey}>
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit" variant="body2">{modelData.name}</Typography>
                <Typography variant="caption">{modelData.description}</Typography>
                <Typography variant="caption">Token Limit: {Math.floor(modelData.tokenLimit * 0.9).toLocaleString()}</Typography>
              </React.Fragment>
            }
            arrow
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <ModelIcon>{modelData.icon}</ModelIcon>
              <span style={{ fontSize: '0.875rem' }}>{modelData.name}</span>
            </span>
          </Tooltip>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default React.memo(AIModelToggle);