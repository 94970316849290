// Base.js

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import { Container, Grid } from '@mui/material';
import { signOut } from "firebase/auth";
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const Base = ({
  onThemeToggle,
  darkState,
  children
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>LGT Research AI</title>
        <meta property="og:title" content="LGT Research AI" />
        <meta property="og:description" content="LGT Research AI is a cutting-edge web application that harnesses state-of-the-art language models to extract insights from sell-side research reports in PDF format." />
        <meta property="og:image" content="/static/images/dvicon.png" />
      </Helmet>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={0}>
          <Grid item xs={12} md={isSidebarOpen ? 2 : 0.5}>
            <Sidebar
              onThemeToggle={onThemeToggle}
              darkState={darkState}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              onLogout={handleLogout}
            />
          </Grid>
          <Grid item xs={12} md={isSidebarOpen ? 10 : 11.5}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Base;